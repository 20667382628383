import React, { useState } from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';

const StyledInsurancePanel = styled.section`
    background: ${colors.greenLight};
    border-radius: 20px;
    cursor: pointer;
    transition: border 0.2s ease-in-out;
    border: 2px solid ${colors.greenLight};
    margin: 145px 25px 120px;
    padding: 140px 30px 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dark-green {
        transition: 0.1s ease-in;
        color: ${colors.greenDark};
    }
    .light-green {
        transition: 0.1s ease-in;
        color: #97df79;
    }
    .top-image {
        position: absolute;
        top: -80px;
        img {
            width: 240px;
        }
    }

    h4 {
        margin: 30px 0em 0em;
        font-weight: 600;
        font-size: 28px;
        font-family: 'Lato';
        line-height: 132.5%;
        color: ${colors.offBlack};
    }
    p {
        color: #656c75;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        span {
            color: ${colors.blueMedium};
            font-weight: 600;
        }
    }
    p + p {
        margin-top: 0px;
        align-self: start;
        font-weight: 600;
        font-size: 24px;
        line-height: 132.5%;
        /* identical to box height, or 32px */
        color: ${colors.greenDark};
        text-decoration-line: underline;
        &:hover {
        }
    }
    @media ${device.tablet} {
        margin: 0px 25px;
        padding: 00px 200px 40px 40px;
        height: 200px;
        h4 {
            align-self: start;
        }
        p {
            padding-right: 90px;
        }
        .top-image {
            position: absolute;
            top: -30px;
            right: 00px;
            img {
                width: 280px;
            }
        }
    }
    @media ${device.laptopS} {
        margin: -80px auto 0px;
        width: 560px;
        padding: 00px 200px 40px 40px;
        height: 200px;
        h4 {
            align-self: start;
        }
        p {
            padding-right: 90px;
        }
        .top-image {
            position: absolute;
            top: -30px;
            right: 00px;
            img {
                width: 280px;
            }
        }
    }
    @media ${device.laptop} {
        margin: -70px auto 0px;
        width: 560px;
        padding: 00px 200px 40px 40px;
        height: 300px;
        :hover {
            border: 2px solid darkgreen;
        }
        h4 {
            margin: 40px 0em 0em;
            font-weight: 600;
            font-size: 34px;
        }
        p {
            color: #656c75;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
            font-size: 22px;
        }
        .top-image {
            position: absolute;
            top: -30px;
            right: -70px;
            img {
                width: 370px;
            }
        }
    }
    @media ${device.desktop} {
        margin: -30px auto 0px;
    }
`;

export const InsurancePanel: React.FC = () => {
    const [hovered, setHovered] = useState(false);
    return (
        <StyledInsurancePanel
            hovered={hovered}
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
        >
            <a
                href="https://woodmoor-family-dentistry.illumitrac.com/"
                target="_blank"
                rel="noreferrer"
            >
                <StaticImage
                    className="top-image"
                    src="../images/insurance-forms.png"
                    alt="insurance form"
                    placeholder="none"
                    quality={100}
                />
                <h4>No Insurance? No Worry!</h4>
                <p>
                    Are you a patient of our practice without dental insurance? Then join our{' '}
                    <span>Smile Club,</span> taking care of your preventive care with a monthly
                    subscription, and save money today!
                </p>
                <p className={`${hovered ? 'light-green' : 'dark-green'}`}>Sign Up Now!</p>
            </a>
        </StyledInsurancePanel>
    );
};
