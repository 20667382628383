export const navMenu = [
    {
        name: 'About Us',
        url: '/about-us/',
        subMenu: [
            {
                name: 'Meet Dr. Dethloff',
                url: '/about-us/meet-dr-dethloff/',
            },
            {
                name: 'Insurance',
                url: '/about-us/insurance/',
            },
            {
                name: 'Patient Forms',
                url: '/about-us/new-patient-forms/',
            },
            {
                name: 'Notice of Privacy Practices',
                url: '/NPP-Woodmoor.pdf',
            },
        ],
    },
    {
        name: 'Emergency Dentistry',
        url: '/emergency-dentistry/',
        subMenu: [
            {
                name: 'Abscesses',
                url: '/emergency-dentistry/abscesses/',
            },
            {
                name: 'Fillings & Crowns',
                url: '/emergency-dentistry/lost-fillings-and-crowns/',
            },
            {
                name: 'Mouth Injuries',
                url: '/emergency-dentistry/mouth-injuries/',
            },
        ],
    },
    {
        name: 'General Dentistry',
        url: '/general-dentistry/',
        subMenu: [
            {
                name: 'Implants',
                url: '/general-dentistry/dental-implants/',
            },
            {
                name: 'Bridges',
                url: '/general-dentistry/bridges/',
            },
            {
                name: 'Cavities & Fillings',
                url: '/general-dentistry/cavities-and-fillings/',
            },
            {
                name: 'Crowns',
                url: '/general-dentistry/crowns/',
            },
            {
                name: 'Dentures',
                url: '/general-dentistry/dentures/',
            },
            {
                name: 'Gum Disease',
                url: '/general-dentistry/gum-disease/',
            },
            {
                name: 'Exams & X-Rays',
                url: '/general-dentistry/oral-exams-and-xrays/',
            },
            {
                name: 'Teeth Cleaning',
                url: '/general-dentistry/teeth-cleaning/',
            },
        ],
    },
    {
        name: 'Cosmetic Dentistry',
        url: '/cosmetic-dentistry/',
        subMenu: [
            {
                name: 'ClearCorrect Braces',
                url: '/cosmetic-dentistry/clearcorrect-braces/',
            },
            {
                name: 'Teeth Whitening',
                url: '/cosmetic-dentistry/teeth-whitening/',
            },
            {
                name: 'Veneers',
                url: '/cosmetic-dentistry/veneers/',
            },
        ],
    },
    {
        name: 'Oral Surgery',
        url: '/oral-surgery/',
        subMenu: [
            {
                name: 'Root Canals',
                url: '/oral-surgery/root-canals/',
            },
            {
                name: 'Tooth Extractions',
                url: '/oral-surgery/tooth-extractions/',
            },
        ],
    },
    {
        name: 'Blog',
        url: '',
        subMenu: [
            {
                name: 'Root Canals',
                url: '/blog/root-canals/',
            },
        ],
    },
];
