import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { Button } from './layout/StyledComponents';

const StyledContactForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${device.laptop} {
        flex-direction: row-reverse;
        justify-content: space-around;
        max-width: 1223px;
        margin: 0 auto;
    }
`;

const FormContainer = styled.div`
    padding: 25px;
    button {
        display: flex;
        justify-content: center;
    }
    h5 {
        font-family: 'Noto Serif';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 132.5%;
        color: ${colors.blueMedium};
        margin: 0em 0px 00px;
    }
    p {
        margin-top: 14px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        padding-bottom: 20px;
        line-height: 132.5%;
        border-bottom: 2px solid ${colors.grey};
        width: 75vw;
        color: ${colors.grey};
    }

    form {
        display: flex;
        font-family: 'lato';
        flex-direction: column;
        label {
            margin: 10px 0px;
        }
        label:focus {
        }
        input,
        select,
        textarea {
            color: ${colors.offBlack};
            opacity: 0.8;
            padding-bottom: 10px;
            margin-top: 10px;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            font-size: 0.9em;
            border-bottom: 1px solid ${colors.grey};
        }
        input[type='text']::placeholder {
            font-family: 'lato';
        }
        input[type='text']:focus,
        input[type='date']:focus,
        input[type='email']:focus,
        input[type='tel']:focus,
        textarea,
        select {
            outline: none;
        }
        select {
        }
        input[type='text']:focus::placeholder,
        input[type='tel']:focus::placeholder,
        input[type='email']:focus::placeholder,
        textarea:focus::placeholder {
            color: ${colors.grey};
        }
        input:focus,
        select:focus,
        textarea:focus {
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 1px solid ${colors.blueLight};
        }
        label {
            display: flex;
            flex-direction: column;
        }
    }
    @media ${device.laptop} {
        padding: 0px 0px 124px;
        width: 434px;
        .input-wrap {
            input {
                width: 190px;
            }
            display: flex;
            justify-content: space-between;
        }
        h5 {
            width: 500px;
            font-size: 40px;
        }
        p {
            padding-right: 90px;
            width: 350px;
        }
    }
`;

const SideImageContainer = styled.div`
    padding: 25px;
    position: relative;
    .blue-squares {
        display: none;
    }
    img {
        border-radius: 100px 20px;
    }
    @media ${device.tablet} {
        margin-top: 60px;
        img {
            width: 450px;
        }
        .blue-squares {
            display: block;
            width: 183px;
            border-radius: 60px 20px;
            z-index: -1;
            position: absolute;
            height: 183px;
            background: ${colors.blueDark};
        }
        .blue-squares:first-of-type {
            top: -1em;
            right: -1em;
        }
        .blue-squares:last-of-type {
            bottom: -1em;
            left: -1em;
        }
    }
    @media ${device.laptop} {
        position: relative;
        img {
            height: 380px;
            width: 442px;
            margin: 0 auto;
        }
        .blue-squares {
            display: block;
            width: 183px;
            border-radius: 60px 20px;
            z-index: -1;
            position: absolute;
            height: 183px;
            background: ${colors.blueDark};
        }
        .blue-squares:first-of-type {
            top: -1.5em;
            right: -1.5em;
        }
        .blue-squares:last-of-type {
            bottom: -1.5em;
            left: -1.5em;
        }
    }
    @media ${device.laptopL} {
        position: relative;
        img {
            height: 400px;
            width: 502px;
        }
    }
`;

export const ContactForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledContactForm>
            <FormContainer>
                {' '}
                <h5>Appointment Request</h5>
                <p>Compassionate & Family Friendly Care</p>
                <div className="white-line"></div>
                <form onSubmit={onSubmit} name="contact">
                    <div className="input-wrap">
                        <label htmlFor="first-name">
                            First Name
                            <input
                                required
                                name="first-name"
                                type="text"
                                id="first-name"
                                placeholder="John"
                            />
                        </label>
                        <label htmlFor="last-name">
                            Last Name
                            <input
                                type="text"
                                name="last-name"
                                required
                                id="last-name"
                                placeholder="Doe"
                            />
                        </label>
                    </div>
                    <label htmlFor="email">
                        Email
                        <input
                            name="email"
                            type="email"
                            required
                            id="email"
                            placeholder="johndoe@gmail.com"
                        />
                    </label>
                    <label htmlFor="phone">
                        Phone Number
                        <input
                            name="tel"
                            type="tel"
                            required
                            id="phone"
                            placeholder="(000) 000-0000"
                        />
                    </label>
                    <label htmlFor="date">
                        Preferred Date
                        <input
                            name="date"
                            type="date"
                            required
                            id="date"
                            placeholder="mm/dd/yyyy"
                        />
                    </label>
                    <label htmlFor="phone">
                        Are you a new patient?
                        <select id="patient" name="patient">
                            <option value="Yes, I am a potential new patient">
                                Yes, I am a potential new patient
                            </option>
                            <option value="No, I am a returning patient">
                                No, I am a returning patient
                            </option>
                        </select>
                    </label>
                    <label htmlFor="notes">
                        How might we help you?
                        <textarea id="notes" name="notes" />
                        {submitState === 'submitted' ? (
                            <>
                                <h3>
                                    Message sent! Somebody from our office will be in touch shortly.
                                </h3>
                            </>
                        ) : (
                            <Button
                                disabled={submitState === 'submitting'}
                                color="blue"
                                type="submit"
                            >
                                {submitState === 'submitting'
                                    ? 'Sending message...'
                                    : 'Submit Form'}
                            </Button>
                        )}
                    </label>
                    <p
                        style={{
                            fontWeight: 'bold',
                            color: `${colors.blueMedium}`,
                            transition: '.2s ease-in',
                            opacity: `${submitState === 'submitted' ? '1' : '0'}`,
                        }}
                    >
                        Thank You! Your message has been sent
                    </p>
                </form>
            </FormContainer>
            <SideImageContainer>
                {' '}
                <div className="blue-squares"></div>{' '}
                <StaticImage
                    alt="dentist explaining to patient"
                    src="../images/dentist-explaining.jpeg"
                    placeholder="none"
                    quality={100}
                />
                <div className="blue-squares"></div>
            </SideImageContainer>
        </StyledContactForm>
    );
};
