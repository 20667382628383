import { Global, css } from '@emotion/react';
import React from 'react';

export const colors = {
    blueDark: '#00377A',
    blueMedium: '#144BBF',
    blueLight: '#00BBFF',
    blueExtraDark: '#1C264D',
    greenLight: '#E4F8D3',
    greenDark: '#136C14',
    offWhite: '#FCFCFC',
    offBlack: '#212324',
    offGrey: '#C0CBCF',
    grey: '#95A0A9',
};
export const fonts = {
    primary: 'Montserrat',
    secondary: 'BioRhyme',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '630px',
    laptop: '1030px',
    laptopL: '1360px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet}) and (orientation:portrait)`,
    laptopS: `(min-width: ${size.tablet}) and (orientation:landscape)`,
    laptop: `(min-width: ${size.laptop}) and (orientation:landscape)`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={css`
                input {
                    border: none;
                    border-radius: none;
                }
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    height: -webkit-fill-available;
                    button {
                        cursor: pointer;
                    }
                }
                body {
                    overflow-x: hidden;
                    font-family: 'Lato';
                    min-height: 100vh; /* fall-back */
                    min-height: -moz-available;
                    min-height: -webkit-fill-available;
                    min-height: fill-available;
                    margin: 0em;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    min-height: 100vh;
                    /* mobile viewport bug fix */
                    min-height: -webkit-fill-available;
                }
                a {
                    text-decoration: none;
                }

                textarea {
                    font-family: 'inter';
                }
                p {
                    font-weight: 400;
                    font-family: ${fonts.primary};
                    font-size: 18px;
                    line-height: 32px;
                    letter-spacing: -0.5625px;
                }
                input[type='date'] {
                    font-family: 'lato';
                }
                ::-webkit-calendar-picker-indicator {
                    width: 18px;
                    height: 18px;
                }
                ::-webkit-calendar-picker-indicator:focus {
                    background: green;
                }
                textarea:focus {
                    outline: 2px solid ${colors.blueDark}; /* oranges! yey */
                }
                @media ${device.mobileL} {
                    .container {
                        max-width: 370px;
                        margin: 0 auto;
                    }
                }
                @media ${device.tablet} and (orientation: landscape) {
                    .container {
                        margin: 0 auto;
                        padding: 0em 1em;
                    }
                }
                @media ${device.tablet} and (max-width: 1000px) and (orientation: portrait) {
                    .container {
                        margin: 0 auto;
                        max-width: 690px;
                    }
                }

                @media ${device.laptop} {
                    .container {
                        margin: 0 auto;
                        max-width: 1110px;
                    }
                }
                @media ${device.desktop} {
                    .container {
                        margin: 0 auto;
                        max-width: 1110px;
                    }
                }
            `}
        />
    );
};
