import React, { useState, createContext } from 'react';

export const siteContext = createContext(null);

export const SiteContextProvider = ({ children }) => {
    const [desktopActive, setDesktopActive] = useState(false);
    return (
        <siteContext.Provider value={{ desktopActive, setDesktopActive }}>
            {children}
        </siteContext.Provider>
    );
};
