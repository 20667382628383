import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { details } from './details';
import { Link } from 'gatsby';

import { Button } from './layout/StyledComponents';

const HoursImageContainer = styled.div`
    position: relative;
    .hours-hero-image {
        position: absolute;
        top: 0em;
        height: 100%;
        z-index: -2;
    }
    .shadow {
        position: absolute;
        top: 0em;
        width: 100vw;
        height: 100%;
        z-index: -1;
    }
    .shadow {
        position: absolute;
        background: #1c264d;
        opacity: 0.75;
    }
    @media ${device.tablet} {
        margin-top: 128px;
    }
    @media ${device.laptopS} {
        margin-top: 128px;
        .hours-hero-image {
            height: 450px;
        }
        .shadow {
            height: 450px;
            display: block;
        }
    }
`;

const StyledHours = styled.div``;

const Text = styled.div`
    color: ${colors.offWhite};
    padding: 20px;
    h4 {
        font-family: 'Noto Serif';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 0px;
        line-height: 132.5%;
    }
    h4 + p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        margin-top: 0px;
        padding-bottom: 10px;
        font-size: 24px;
        line-height: 132.5%;
        /* or 32px */
        border-bottom: 2px solid ${colors.grey};
        /* Gray/gray3 */

        color: #c0cbcf;
    }
    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160.5%;
    }
    .address-container {
        align-items: center;
        display: flex;
        margin-top: 20px;
        p {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 45px;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-decoration-line: underline;
            font-family: 'lato';
            color: ${colors.offWhite};
        }
    }
    @media ${device.tablet} {
        padding: 2px 80px;
        margin: 0 auto;
    }
    @media ${device.laptopS} {
        display: flex;

        padding: 25px 80px;
        margin: 0 auto;
        max-width: 1060px;
        justify-content: space-between;
    }
`;

const FirstColumn = styled.section`
    color: ${colors.offWhite};
    .special-button {
        color: ${colors.blueMedium};
        background: ${colors.offWhite};
        &:hover {
            color: ${colors.blueMedium};
            background: #9af9ff;
        }
    }
    @media ${device.tablet} {
        button {
            margin-top: 20px;
        }
    }
    @media ${device.laptopS} {
        p + p {
            max-width: 400px;
        }
    }
    button {
        margin-top: 20px;
    }
`;

const SecondColumn = styled.section`
    color: ${colors.offWhite};

    div {
        display: flex;
        justify-content: space-between;
    }
    ul {
        padding: 0;
    }
    ul:first-of-type {
        font-weight: 600;
    }
    ul:last-of-type {
        text-align: right;
    }
    li {
        margin: 0px 0px 20px 0px;
        list-style: none;
    }
    @media ${device.tablet} {
        width: 275px;
    }
`;

export const Hours: React.FC = () => {
    return (
        <StyledHours>
            <HoursImageContainer>
                <div className="shadow"></div>

                <StaticImage
                    className="hours-hero-image"
                    src="../images/woodmor-office.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="office"
                />
                <Text>
                    <FirstColumn>
                        <h4>Be Our Guest</h4>
                        <p>Everyone Is Welcomed to Our Office!</p>
                        <p>
                            We are located at the East corner of 1-25 and Hwy 105. Just behind
                            Woodmoor Lake and at the base of Monument Hill.
                        </p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://goo.gl/maps/peB14nSHhvuGm2ph7"
                        >
                            <div className="address-container">
                                <StaticImage
                                    src="../images/svgs/marker-white.svg"
                                    placeholder="none"
                                    quality={100}
                                    alt="location"
                                />
                                <p>{details.address}</p>
                            </div>
                        </a>
                        <Link to="tel:(719) 488-3014">
                            <Button className="special-button" color="white">
                                <StaticImage
                                    className="blue-mobile"
                                    src="../images/svgs/phone-blue.svg"
                                    placeholder="none"
                                    alt="white mobile phone"
                                    quality={100}
                                />
                                Call Us at (719) 488-3014
                            </Button>
                        </Link>
                    </FirstColumn>
                    <SecondColumn>
                        <h4>Our Office Hours</h4>
                        <div>
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li>Sunday</li>
                            </ul>
                            <ul>
                                <li>8:00 AM - 5:00 PM</li>
                                <li>8:00 AM - 5:00 PM</li>
                                <li>8:00 AM - 5:00 PM</li>
                                <li>8:00 AM - 5:00 PM</li>
                                <li>8:00 AM - 5:00 PM</li>
                                <li>Closed</li>
                                <li>Closed</li>
                            </ul>
                        </div>
                    </SecondColumn>
                </Text>
            </HoursImageContainer>
        </StyledHours>
    );
};
