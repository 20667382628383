/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from '../layout/GlobalStyles';
import { navMenu } from './navMenu';
import { Button } from '../layout/StyledComponents';
import { siteContext } from '../../layout/SiteContextProvider';

interface Props {
    home?: boolean;
    mobileActive?: boolean;
    navClassName: string;
}
const Container = styled.div<Props>`
    .white {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        transition: 0.2s ease-in-out;
        background: ${colors.offWhite};
        li {
            background: none;
            a {
                color: black;
            }
        }
    }
`;

const StyledNav = styled.nav<Props>`
    position: fixed;
    top: 0em;
    z-index: 5;
    width: 100vw;
    box-shadow: ${({ home }) =>
        home
            ? 'none'
            : `rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;`};
    height: ${({ home }) => (home ? '70px' : `70px`)};

    .desktop-button,
    .desktop-button-container {
        display: none;
    }

    .logo {
        margin: 0;
        position: relative;
        bottom: 5px;
        width: 100px;
    }
    .nav-container {
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding: 15px 20px;
    }
    .icon-container {
        display: flex;
        width: 84px;
        justify-content: space-between;
    }
    .desktop-menu {
        display: none;
    }
    @media ${device.tablet} {
        z-index: 1;

        height: ${({ home }) => (home ? '0px' : '108px')};
        .nav-container {
            padding: 25px 30px;
        }
        .logo {
            width: 130px;
        }
        .desktop-button {
            display: block;
        }
        .desktop-button,
        .desktop-button-container {
            border-radius: 9px;

            background: ${({ navClassName }) =>
                navClassName === 'white' ? `${colors.blueDark}` : ''};
        }
    }
    @media ${device.laptopS} {
        z-index: 1;
    }
    @media ${device.laptop} {
        height: ${({ home }) => (home ? '108px' : '108px')};
        .desktop-button,
        .desktop-button-container {
            border-radius: 9px;
            display: flex;
            background: ${({ navClassName }) =>
                navClassName === 'white' ? `${colors.blueDark}` : ''};
        }
        .payment-button {
            display: none;
        }

        .desktop-button-container {
            display: flex;
            align-items: center;

            > div {
                position: relative;

                right: 25px;
            }
        }
        .desktop-button {
            margin-right: 16px;
        }
        .desktop-menu {
            padding: 0em;
            width: 881px;
            display: flex;
            list-style: none;
            justify-content: space-around;
            a {
                display: flex;
                align-items: center;
            }

            li {
                position: relative;
            }
            div {
                position: relative;
                left: 5px;
            }
            .desktop-dropdown-menu {
                background: ${colors.offWhite};
                left: 00px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
                    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                margin-top: 39px;
                width: 220px;
                border-radius: 15px;
                li {
                    a {
                        color: ${colors.blueMedium};
                    }
                    padding: 10px 20px;
                    font-weight: 600;

                    margin: 0.5em 0em;
                    &:hover {
                        color: ${colors.blueMedium};
                        font-weight: 800;
                        background: rgba(81, 142, 233, 0.3);
                    }
                }
                position: absolute;
            }

            li,
            a {
                color: ${({ home }) => (home ? `${colors.offWhite}` : `${colors.offBlack}`)};
            }
        }
        .nav-container {
            padding: 20px 20px;
        }
        .logo {
            width: 144px;
        }
        .icon-container {
            display: none;
        }
    }
    @media ${device.laptopL} {
        .desktop-button-container {
            > div {
                right: 35px;
            }
        }
        .payment-button {
            display: block;
        }
        .desktop-menu {
            padding: 0em;
            display: flex;
        }
        .nav-container {
            padding: 20px 40px;
        }
    }
    @media ${device.desktopL} {
        .logo {
            width: 164px;
            height: 73px;
        }
        .desktop-menu {
            width: 1080px;
            li {
                transition: font-size 0.2s ease-in;
                font-size: 19px;
            }
        }
    }
`;

const Burger = styled.button<Props>`
    display: flex;
    z-index: 2;
    flex-direction: column;
    align-items: end;
    max-width: 25px;

    div {
        background: ${({ home }) => (home ? `${colors.offWhite}` : `${colors.blueDark}`)};
        background: ${({ home, navClassName }) =>
            home && navClassName == 'white' && `${colors.blueDark}`};
        background: ${({ mobileActive }) => mobileActive && `${colors.offWhite}`};
        height: 2px;
        margin: 3px 0px;
    }
    div:first-of-type {
        width: ${({ mobileActive }) => (!mobileActive ? '24px' : '20px')};

        transition: 0.2s ease-in;
        transform: ${({ mobileActive }) =>
            !mobileActive ? 'rotate(0)' : 'translate(00%, 400%) rotate(45deg)'};
    }
    /* middle line of burger */
    div:first-of-type + div {
        width: 18px;
        opacity: ${({ mobileActive }) => (!mobileActive ? '1' : '0')};
        transition: opacity 0.2s ease-in;
    }
    div:last-of-type {
        width: ${({ mobileActive }) => (!mobileActive ? '20px' : '20px')};
        transition: 0.2s ease-in;
        transform: ${({ mobileActive }) =>
            !mobileActive ? 'rotate(0)' : 'translate(-00%, -380%) rotate(-45deg)'};
    }
`;

interface MobileProps {
    mobileActive: boolean;
}

const MobileMenu = styled.div<MobileProps>`
    position: absolute;
    background: ${colors.blueMedium};
    top: 0px;
    height: 100vh;
    width: 100vw;
    transition: transform 0.1s ease-in;
    transform: ${({ mobileActive }) => (mobileActive ? 'translateX(000%)' : 'translateX(100%)')};
    display: flex;
    padding-top: 20vh;
    justify-content: center;
    z-index: 0;
    .back-button {
        display: flex;
        align-items: center;
        border: none;
        position: relative;
        right: 20px;
        margin-top: 30px;
        text-decoration: underline;
        background: none;
        color: ${colors.offWhite};
        font-weight: 600;
        font-size: 16px;
        font-family: 'lato';
    }
    .fade-in {
        opacity: 1;
        animation-name: slidein;
        animation-duration: 0.2s;
    }
    .slide-fade {
        opacity: 1;
        animation-name: slide-fade;
        animation-duration: 0.3s;
    }
    @keyframes slide-fade {
        from {
            opacity: 0;
            transform: translateX(20%);
        }

        to {
            opacity: 1;
            transform: translateX(00%);
        }
    }

    @keyframes slidein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    .menu-container {
        margin-right: 25px;
        ul {
            padding-left: 0;
            list-style: none;
            color: ${colors.offWhite};
            li {
                display: flex;
                align-items: center;
                a {
                    color: ${colors.offWhite};
                    display: flex;
                    align-items: center;
                }
                font-weight: 600;
                margin-bottom: 1em;
                font-size: 20px;
                div {
                    position: relative;
                    left: 10px;
                }
            }
        }
    }
`;

const debounce = (func, ms) => {
    let timer;
    return (...args) => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, ms);
        }
    };
};

export const Nav: React.FC<Props> = ({ home }) => {
    const { desktopActive, setDesktopActive } = useContext(siteContext);
    const [menu, setMenu] = useState(null);
    const [indexState, setIndexState] = useState(null);
    const [mobileActive, setMobileActive] = useState(false);

    const isSSR = typeof window === 'undefined';

    const [navClassName, setNavClassName] = useState('');
    const [position, setPosition] = useState(isSSR ? 0 : window.scrollY);

    const debounceMs = 200;

    const onScroll = useCallback(
        debounce(() => setPosition(window.scrollY), 300),
        [debounceMs, setPosition]
    );

    useEffect(() => {
        if (isSSR) return;

        window.addEventListener('scroll', onScroll);
        if (position > 700) {
            setNavClassName('white');
        } else {
            setNavClassName('clear');
        }
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [position]);

    function mobileItemClick(item) {
        setMenu(item);
        console.log(menu);
    }

    function desktopHover(item, index) {
        setMenu(item);
        setIndexState(index);
        setDesktopActive(true);
    }
    return (
        <Container navClassName={navClassName}>
            <StyledNav
                navClassName={navClassName}
                className={`${home ? navClassName : 'white'}`}
                home={home}
            >
                <MobileMenu mobileActive={mobileActive}>
                    <div className={`menu-container`}>
                        <ul>
                            {!menu &&
                                navMenu.map(navItem => {
                                    return (
                                        <li
                                            className="fade-in"
                                            onClick={() => mobileItemClick(navItem)}
                                            key={navItem.name}
                                        >
                                            {navItem.name}{' '}
                                            <StaticImage
                                                src="../../images/svgs/arrow-right-white.svg"
                                                placeholder="none"
                                                alt="expand menu"
                                                quality={100}
                                            />
                                        </li>
                                    );
                                })}
                            {menu &&
                                menu.subMenu.map(navItem => {
                                    return (
                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                        <li
                                            className="fade-in"
                                            onClick={() => {
                                                setMobileActive(false);
                                                setMenu(null);
                                            }}
                                            key={navItem.name}
                                        >
                                            {navItem.url.endsWith('.pdf') ? (
                                                <a
                                                    href={navItem.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {navItem.name}{' '}
                                                    <StaticImage
                                                        src="../../images/svgs/arrow-right-white.svg"
                                                        placeholder="none"
                                                        alt="expand menu"
                                                        quality={100}
                                                    />
                                                </a>
                                            ) : (
                                                <Link to={navItem.url}>
                                                    {navItem.name}{' '}
                                                    <StaticImage
                                                        src="../../images/svgs/arrow-right-white.svg"
                                                        placeholder="none"
                                                        alt="expand menu"
                                                        quality={100}
                                                    />
                                                </Link>
                                            )}
                                        </li>
                                    );
                                })}
                        </ul>
                        {menu && (
                            <button
                                onClick={() => setMenu(null)}
                                className="slide-fade back-button"
                            >
                                <StaticImage
                                    style={{ transform: 'scaleX(-1)', marginRight: '10px' }}
                                    src="../../images/svgs/arrow-right-white.svg"
                                    placeholder="none"
                                    alt="expand menu"
                                    quality={100}
                                />
                                Back{' '}
                            </button>
                        )}
                    </div>
                </MobileMenu>
                <div className="nav-container">
                    {!mobileActive && home && navClassName == 'clear' && (
                        <Link to="/">
                            <StaticImage
                                className="logo"
                                src={`../../images/svgs/logo-white.svg`}
                                placeholder="none"
                                quality={100}
                                alt="woodmore logo"
                                loading="eager"
                            />
                        </Link>
                    )}
                    {!mobileActive && home && navClassName == 'white' && (
                        <Link to="/">
                            <StaticImage
                                className="logo"
                                src={`../../images/svgs/logo-color.svg`}
                                placeholder="none"
                                quality={100}
                                alt="woodmore logo"
                                loading="eager"
                            />
                        </Link>
                    )}
                    {!mobileActive && !home && (
                        <>
                            <Link to="/">
                                <StaticImage
                                    className="logo"
                                    src={`../../images/svgs/logo-color.svg`}
                                    placeholder="none"
                                    quality={100}
                                    alt="woodmore logo"
                                    loading="eager"
                                />
                            </Link>
                        </>
                    )}
                    {mobileActive && (
                        <Link to="/">
                            <StaticImage
                                className="logo"
                                src={`../../images/svgs/logo-white.svg`}
                                placeholder="none"
                                quality={100}
                                alt="woodmore logo"
                                loading="eager"
                            />
                        </Link>
                    )}
                    <div className="icon-container">
                        {home && navClassName === 'clear' && (
                            <Link to="tel:(719) 488-3014">
                                <StaticImage
                                    src={`../../images/svgs/phone-white.svg`}
                                    placeholder="none"
                                    quality={100}
                                    alt="woodmore logo"
                                />
                            </Link>
                        )}
                        {home && navClassName === 'white' && (
                            <Link to="tel:(719) 488-3014">
                                <StaticImage
                                    src={`../../images/svgs/phone-blue.svg`}
                                    placeholder="none"
                                    quality={100}
                                    alt="woodmore logo"
                                />
                            </Link>
                        )}
                        {!home && (
                            <Link to="tel:(719) 488-3014">
                                <StaticImage
                                    src={`../../images/svgs/phone-blue.svg`}
                                    placeholder="none"
                                    quality={100}
                                    alt="woodmore logo"
                                />
                            </Link>
                        )}
                        {/* {home ? (
                        <StaticImage
                            src="../../images/svgs/search-white.svg"
                            placeholder="none"
                            quality={100}
                            alt="woodmore logo"
                        />
                    ) : (
                        <StaticImage
                            src="../../images/svgs/search-blue.svg"
                            placeholder="none"
                            quality={100}
                            alt="woodmore logo"
                        />
                    )} */}
                        <Burger
                            navClassName={navClassName}
                            mobileActive={mobileActive}
                            onClick={() => setMobileActive(active => !active)}
                            style={{ background: 'none', border: 'none' }}
                            home={home}
                        >
                            <div className="first-line"></div>
                            <div className="second-line"></div>
                            <div className="third-line"></div>
                        </Burger>
                    </div>
                    <ul className={`desktop-menu`}>
                        {navMenu.map((item, index) => {
                            return (
                                <li
                                    className={`${navClassName}-nav`}
                                    onMouseEnter={() => desktopHover(item, index)}
                                    key={item.name}
                                >
                                    <Link to={item.url}>
                                        {item.name}
                                        {home && navClassName === 'clear' && (
                                            <StaticImage
                                                src="../../images/svgs/arrow-down-white.svg"
                                                placeholder="none"
                                                alt="expand menu"
                                                quality={100}
                                            />
                                        )}
                                        {!home && (
                                            <StaticImage
                                                src="../../images/svgs/arrow-down-black.svg"
                                                placeholder="none"
                                                alt="expand menu"
                                                quality={100}
                                            />
                                        )}
                                        {home && navClassName === 'white' && (
                                            <StaticImage
                                                src="../../images/svgs/arrow-down-black.svg"
                                                placeholder="none"
                                                alt="expand menu"
                                                quality={100}
                                            />
                                        )}
                                    </Link>
                                    {
                                        <div className="desktop-dropdown-menu">
                                            {desktopActive &&
                                                indexState === index &&
                                                menu &&
                                                menu.subMenu.map(navItem => {
                                                    return (
                                                        <li
                                                            onClick={() => setDesktopActive(false)}
                                                            key={navItem.key}
                                                        >
                                                            {navItem.url.endsWith('.pdf') ? (
                                                                <a
                                                                    href={navItem.url}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {navItem.name}
                                                                </a>
                                                            ) : (
                                                                <Link to={navItem.url}>
                                                                    {navItem.name}
                                                                </Link>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                        </div>
                                    }
                                </li>
                            );
                        })}
                    </ul>
                    <div className="desktop-button-container">
                        {/* {home ? (
                        <StaticImage
                            src="../../images/svgs/search-white.svg"
                            placeholder="none"
                            quality={100}
                            alt="woodmore logo"
                        />
                    ) : (
                        <StaticImage
                            src="../../images/svgs/search-blue.svg"
                            placeholder="none"
                            quality={100}
                            alt="woodmore logo"
                        />
                    )} */}
                        <Link to="tel:(719) 488-3014">
                            <Button className="desktop-button" color={home ? 'white' : 'blue'}>
                                Contact Us
                            </Button>
                        </Link>
                        <Link
                            className="payment-button"
                            to="https://link.clover.com/urlshortener/wNdBTJ"
                        >
                            <Button color="blue">Make Payment</Button>
                        </Link>
                    </div>
                </div>
            </StyledNav>
        </Container>
    );
};
