import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { details } from './details';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';

const StyledFooter = styled.footer`
    .background {
        position: absolute;
        max-height: 620px;
        z-index: -1;
    }
    z-index: 1;
    position: relative;
    background: #1c264d;
    color: ${colors.offWhite};
    .special-button {
        color: ${colors.blueMedium};
        background: ${colors.offWhite};
        &:hover {
            color: ${colors.blueMedium};
            background: #9af9ff;
        }
    }
    a {
        z-index: 1;
        color: ${colors.offWhite};
        transition: 0.2s ease-in-out;
        &:hover {
            z-index: 1;
            color: #9af9ff;
        }
    }
    .footer-container {
        padding: 50px 25px;
    }

    @media ${device.tablet} {
        .footer-container {
            padding-top: 128px;
            display: flex;
            justify-content: space-between;
            padding: 45px 75px 75px;
        }
    }
    @media ${device.laptopS} {
        .footer-logo {
            width: 177px;
            height: 80px;
        }
        .footer-container {
            max-width: 1033px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 85px 75px 75px;
        }
    }
`;

const LeftColumn = styled.div`
    /* facebook paragraph */
    div > p {
        margin-right: 2em;
        font-family: 'lato';
    }
    button {
        margin: 20px 0px;
    }
    /* address location */
    .address-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        p {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 45px;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-decoration-line: underline;
            font-family: 'lato';
            color: ${colors.offWhite};
            cursor: pointer;
            &:hover {
                color: #9af9ff;
                transition: 0.2s ease-in-out;
            }
        }
    }
    @media ${device.tablet} {
        margin-top: 38px;
    }
`;

const RightColumn = styled.div`
    h6 {
        font-weight: 600;
        font-size: 24px;
        margin: 2em 0em 0.25em;
        line-height: 132.5%;
        /* identical to box height, or 32px */

        /* Gray/gray4 */

        color: #95a0a9;
    }
    ul {
        list-style: none;
        padding: 0em;
        li {
            margin: 1em 0em;
        }
    }
    .address-container {
        align-items: center;
        display: flex;
        margin-top: 20px;
        p {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 45px;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-decoration-line: underline;
            font-family: 'lato';
            color: ${colors.offWhite};
        }
    }
    @media ${device.laptopS} {
        ul {
            li {
                font-size: 18px;
                margin: 1.5em 0em;
            }
        }
        h6 {
            font-weight: 600;
            font-size: 24px;
            margin: 0em 0em 0.25em;
            line-height: 132.5%;
            color: #95a0a9;
        }
        margin-top: 00px;
    }
`;

export const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <StaticImage
                className="background"
                src="../images/svgs/background-footer.png"
                alt="background woods"
                placeholder="none"
                quality={100}
            />
            <div className="footer-container">
                <LeftColumn>
                    <Link to="/">
                        <StaticImage
                            className="footer-logo"
                            src="../images/svgs/logo-white.svg"
                            placeholder="none"
                            quality={100}
                            alt="woodmor dental logo"
                        />
                    </Link>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://goo.gl/maps/peB14nSHhvuGm2ph7"
                    >
                        <div className="address-container">
                            <StaticImage
                                src="../images/svgs/marker-white.svg"
                                placeholder="none"
                                quality={100}
                                alt="location"
                            />
                            <p>{details.address}</p>
                        </div>
                    </a>
                    <Link to="tel:(719) 488-3014">
                        <Button className="special-button" color="white">
                            <StaticImage
                                className="blue-mobile"
                                src="../images/svgs/phone-blue.svg"
                                placeholder="none"
                                alt="white mobile phone"
                            />
                            Call Us at (719) 488-3014
                        </Button>
                    </Link>
                    {/* <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/pages/category/General-Dentist/Woodmoor-Family-Dentistry-110144210360308/"
                    >
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                            <p>Follow us at:</p>
                            <StaticImage
                                src="../images/svgs/facebook-white.svg"
                                placeholder="none"
                                quality={100}
                                alt="facebook logo"
                            />
                        </div>
                    </a> */}
                </LeftColumn>
                <RightColumn>
                    <h6>Navigation</h6>
                    <ul>
                        <li>
                            <Link to="/about-us/">About Us</Link>
                        </li>
                        <li>
                            <Link to="/about-us/meet-dr-dethloff/">Meet Dr. Dethloff</Link>
                        </li>
                        <li>
                            <Link to="/emergency-dentistry/">Emergency Dentistry</Link>
                        </li>
                        <li>
                            <Link to="/general-dentistry/">General Dentistry</Link>
                        </li>
                        <li>
                            <Link to="/oral-surgery/">Oral Surgery</Link>
                        </li>
                        <li>
                            <Link to="/about-us/insurance/">Insurance</Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy/">Privacy Policy</Link>
                        </li>
                    </ul>
                </RightColumn>
            </div>
            <p
                className="sub-text"
                style={{ color: 'grey', padding: '0px 0px 20px 50px', fontFamily: 'lato' }}
            >
                © 2022 WOODMOOR FAMILY DENTISTRY
            </p>
        </StyledFooter>
    );
};
