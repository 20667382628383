import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';

const StyledMap = styled.div`
    .map-container {
        position: relative;
        height: 45vh;
        display: flex;
        justify-content: center;
        border-radius: 15px;
        padding: 25px;
    }

    .blue-squares {
        display: block;
        width: 183px;
        border-radius: 60px 20px;
        z-index: -1;
        position: absolute;
        height: 183px;
        background: ${colors.blueDark};
    }
    .blue-squares:first-of-type {
        top: 2em;
        right: 2em;
    }
    .blue-squares:last-of-type {
        bottom: 2em;
        left: 2em;
    }
    .map {
        border-radius: 15px;
    }
    @media ${device.tablet} {
        .blue-squares:first-of-type {
            top: -1em;
            right: 2em;
        }
        .blue-squares:last-of-type {
            bottom: -1em;
            left: 2em;
        }
        padding: 75px;
        iframe {
            max-width: 470px;
        }
    }
    @media ${device.laptopS} {
        padding: 20px 0px 07px;
        .map-container {
            padding: 0px 0px;
            position: relative;
            width: 766px;
            margin: 80px auto 128px;
            height: 532px;
        }

        .map {
            max-width: 966px;
        }
        .blue-squares:first-of-type {
            top: -3em;
            right: -3em;
        }
        .blue-squares:last-of-type {
            bottom: -3em;
            left: -3em;
        }
    }
    @media ${device.laptop} {
        padding: 25px 0px 0px;
        height: 740px;
        .map-container {
            width: 966px;
        }
    }
`;

export const Map: React.FC = () => {
    return (
        <StyledMap>
            <div className="map-container">
                <div className="blue-squares"></div>
                <iframe
                    className="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3096.5673277720034!2d-104.8603275!3d39.093552100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876cadef01b51ff1%3A0x4c18c8dea91fc471!2s1777%20Lake%20Woodmoor%20Dr%2C%20Monument%2C%20CO%2080132!5e0!3m2!1sen!2sus!4v1654132709911!5m2!1sen!2sus"
                    width="100%"
                    loading="lazy"
                    title="Woodmor Location"
                    height="100%"
                    frameBorder="0"
                    aria-hidden="false"
                />
                <div className="blue-squares"></div>
            </div>
        </StyledMap>
    );
};
