import { colors } from './GlobalStyles';
import styled from '@emotion/styled';

interface Props {
    color: string;
}

export const Button = styled.button<Props>`
    color: ${colors.offWhite};
    font-size: 18px;
    font-weight: 600;
    font-family: 'lato';
    padding: 0.8em 1em;
    border-radius: 5px;
    border: ${({ color }) =>
        color === 'white' ? `2px solid ${colors.offWhite}` : `2px solid ${colors.blueMedium}`};
    display: flex;
    align-items: center;
    background: ${({ color }) => (color === 'white' ? 'none' : `${colors.blueMedium}`)};
    transition: all 0.2s ease-in;
    &:hover {
        border: ${({ color }) => (color === 'white' ? `2px solid #9AF9FF` : `2px solid #518EE9`)};
        background: ${({ color }) => color === 'blue' && '#518EE9'};
        color: ${({ color }) => (color === 'white' ? `#9AF9FF` : `white`)};
        filter: brightness(90%);
    }
    div {
        width: 22px;
        height: auto;
        margin-right: 0.5em;
    }
`;
