import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { Link } from 'gatsby';

const StyledBluePanel = styled.div`
    background: ${colors.blueDark};
    padding: 24px 32px 32px;
    height: 227px;
    margin: 25px;
    border-radius: 15px;
    text-align: center;
    p:first-of-type {
        font-family: 'Lato';
        color: ${colors.offWhite};
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
    }

    span {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 16px;
        color: ${colors.offWhite};
        text-align: center;
        font-family: 'Lato';
    }
    span + p {
        font-family: 'Lato';
        color: #ffc803;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        margin-top: 40px;
        line-height: 27px;
        text-align: center;
    }
    @media ${device.laptopS} {
        width: 436px;
        margin: 50px auto 80px;
        p:first-of-type {
            font-size: 32px;
        }
        span {
            font-size: 18px;
        }
        span + p {
            font-size: 32px;
        }
    }
    @media ${device.laptopL} {
        margin: 50px auto 120px;
    }
`;
// No Insurance? No Problem!
// Call us for more information!
export const BluePanel: React.FC = () => {
    return (
        <div className="container">
            <StyledBluePanel>
                <p>Don’t hesitate to call!</p>
                <span>Feel free to call us with any questions</span>
                <p>
                    <Link to="tel:(719) 488-3014" style={{ color: '#ffc803' }}>
                        (719) 488-3014
                    </Link>
                </p>
            </StyledBluePanel>
        </div>
    );
};
